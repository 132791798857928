/* This example requires Tailwind CSS v2.0+ */

import React from "react"

import { Link } from "gatsby"

export const ColumnGrid = ({ id }) => {
  return (
    <section id={id} className="bg-kinzica-dark p-8 lg:px-16 mx-auto w-full">
      <div className="mx-auto text-center">
        <div className="grid grid-cols-1 gap-1">
          {/* <Link to="/works/sound-design">
						<div className="bg-gray-800 hover:bg-gray-600 lg:text-6xl text-4xl text-white p-24">Sound Design</div>
					</Link> */}
          <Link to="/works/library-music">
            <div className="bg-gray-800 hover:bg-gray-600 lg:text-6xl text-4xl text-white p-24">
              Library music
            </div>
          </Link>
          <Link to="/works/video">
            <div className="bg-gray-800 hover:bg-gray-600 lg:text-6xl text-4xl text-white p-24">
              Videos
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}
