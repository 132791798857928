import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"
import { PageTitle } from "../components/Components/Headings/PageTitle"
import { ColumnGrid } from '../components/Components/Content/ColumnGrid';



const Works = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <Layout>
      <Seo
        title="Kinzica Studio | Our works"
        description="Kinzica Studio - Library music | Sound design | Videos"
        url="www.kinzicastudio.com/works"
      />
      <PageTitle id="works" title="Our works" subtitle="Check our works!" />
      {/* <MainGrid id={"all-works"} projects={projects} /> */}
      <ColumnGrid />
    </Layout >

  )
}

export default Works;
